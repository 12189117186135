import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"

import patientPortalLettersVideo from "src/assets/video/patient-portal-letters.gif"
import padlock from "src/assets/img/graphics/padlock.svg"

const Page = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: For patients"
        pathname="/for-patients"
        description="If you are a patient of a clinic using Carebit, learn more about what Carebit is and how it can help you."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Patient information
              </Badge>
              <h1>Carebit for patients</h1>
              <p className="u-whiteTranslucent">
                Carebit is a digital healthcare platform that is designed to put
                you in control and make securely managing your bookings, letters
                and test results easy and convenient. Learn more about it below.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col md={{ size: 6 }} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Data on demand">
                        Access your letters and test results quickly
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      All your letters and test results - either created by your
                      clinic or those provided to your clinic - are easily and
                      securely accessible via the Patient Portal. No more
                      ringing up to ask for them to be scanned and emailed to
                      you.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - patient portal"
                      src={patientPortalLettersVideo}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={padlock} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Safe & secure">
                        Encryption used throughout
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      With Carebit, you can be sure that your data is safe with
                      us. All data is encrypted in our data centre and you only
                      access it with encrypted links that expire after 15
                      minutes, providing additional security. When you connect
                      to Carebit, it’s only ever over an encrypted SSL
                      connection.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/security">
                        <Button color="secondary" size="md">
                          Learn more about Carebit security &rarr;
                        </Button>
                      </Link>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Link to="/privacy-policy">
                        <Button color="light" size="md">
                          Learn more about our privacy policy &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
        <section className="u-bgLightestGrey">
          <div className="u-centerFlex u-padding20">
            <Col lg={6} md={12}>
              <h2 style={{ marginBottom: "40px" }}>
                Frequently asked questions
              </h2>

              <h5>
                Why have I been signed up to Carebit and why am I receiving
                emails?
              </h5>
              <p>
                The clinic which you have submitted your details to will have
                entered your details into their Carebit account in order to
                provide you with the requested medical services. As part of
                that, you automatically receive an email from us letting you
                know how you can activate your Carebit account to view future
                letters and test results.
              </p>

              <p>
                If you don’t want to use Carebit, that’s not a problem. It’s
                always there for your convenience if you ever do want to.
              </p>

              <h5 style={{ marginTop: "40px" }}>
                I don’t want to use Carebit. Can you deactivate my account?
              </h5>
              <p>
                All Carebit accounts are deactivated by default, and must be
                confirmed by you, the patient, before they can be used. If you
                don’t want to use Carebit’s features, just leave your account as
                it is and it will stay deactivated.
              </p>

              <h5 style={{ marginTop: "40px" }}>
                How do I delete all my data?
              </h5>
              <p>
                Please speak to the clinic to which you are registered to
                enquire about this.
              </p>

              <h5 style={{ marginTop: "40px" }}>How do you protect my data?</h5>
              <p>
                We take security extremely seriously, are registered with the
                ICO in the UK and use a number of encryption techniques and
                best-practices to secure your data. Please see our{" "}
                <Link to="/security">security section</Link> for more details.
              </p>
            </Col>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Page
